import React, { FC } from "react";

import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Link } from "@chakra-ui/react";

interface IHeader {
    itemSelected: number;
}

export const Header: FC<IHeader> = ({ itemSelected }) => {

    const isLoggedIn = !!sessionStorage.getItem('user');
    const isAdmin = !!sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') || '{}')?.admin : false;
    const username = !!sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') || '{}')?.nick : null;

    return (<HeaderContainer>
        <HeaderSection>
            <h1>POKER HAND HISTORY BACKOFFICE</h1>
            { !isLoggedIn ? (<AccountSection>
                <div>
                    <StyledLink href="/register" selected={itemSelected === 5}>REGISTER</StyledLink>
                </div>
                <div>
                    <StyledLink href="/login" selected={itemSelected === 6}>LOGIN</StyledLink>
                </div>
            </AccountSection>) : (<AccountSection>
                <div>
                    <StyledLink>NICK: { username }</StyledLink>
                </div>
                <div>
                    <StyledLink onClick={() => {
                        sessionStorage.removeItem('user');
                        window.location.href = '/';
                    }} selected={false}>LOGOUT</StyledLink>
                </div>
            </AccountSection>)}
            
        </HeaderSection>
        <Menu>
            { isLoggedIn && (<>
                <StyledLink href="/" target="_self" selected={itemSelected === 0}>ADD HAND</StyledLink>
                <StyledLink href="/players" target="_self" selected={itemSelected === 1}>PLAYERS</StyledLink>
                <StyledLink href="/tags" target="_self" selected={itemSelected === 2}>TAGS HANDS</StyledLink>
                <StyledLink href="/tagsPlayers" target="_self" selected={itemSelected === 3}>TAGS PLAYERS</StyledLink>
                <StyledLink href="/hands" target="_self" selected={itemSelected === 4}>HANDS</StyledLink>
                <StyledLink href="/tables" target="_self" selected={itemSelected === 7}>TABLES</StyledLink>
                { isAdmin && (<StyledLink href="/calendar" target="_self" selected={itemSelected === 8}>CALENDAR</StyledLink>) }
                { isAdmin && (<StyledLink href="/groups" target="_self" selected={itemSelected === 9}>GROUPS</StyledLink>) }
                { isAdmin && (<StyledLink href="/users" target="_self" selected={itemSelected === 10}>USERS</StyledLink>) }
            </>) }
        </Menu>
    </HeaderContainer>);
};

const HeaderContainer = styled.div`
`;

const HeaderSection = styled.div`
    width: 100%;
    background-color: ${colors.background.primary};
    color: ${colors.whiteStrong};
    font-weight: 500;
    top: 0;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;

    h1 {
        margin: 0;
        font-size: 20px;
    }
`;

const AccountSection = styled.div`
    position: absolute;
    right: 24px;
    display: flex;
    gap: 32px;
`;

const StyledLink = styled(Link)<{selected?: boolean}>`
    text-decoration: ${p => p.selected ? 'underline' : 'none'};
    color: inherit;
    cursor: pointer;
`;

const Menu = styled.div`
    width: 100vw;
    display: flex;
    background-color: ${colors.background.secondary};
    color: ${colors.whiteStrong};
    position: absolute;
    top: 70px;
    items-align: center;
    justify-content: center;
    padding: 16px 0;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.9;

    > a {
        display: block;
        padding: 0 16px;
        border-left: 2px solid ${colors.soft}; 
    }

    > a:first-child {
        border: none;
    }
`;