import React, {
    FC,
    useEffect, 
    useState, 
} from 'react';

import { Header } from '../../Header/Header';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import ITag, { IInternalPlayer } from '../../../types/Tag.types';
import { Tag } from '../../commons/Tag';
import { CalendarHeader } from './CalendarHeader';
import { DayView } from './DayView';
import { cloneDeep } from 'lodash';
import { WeekView } from './WeekView';
import { MonthView } from './MonthView';
import { YearView } from './YearView';
import { Loading } from '../Players';
import { getWeek } from './Calendar.utils';

const getTitle = (date: Date, type: string) => {
    if (type === 'week' || type === 'month') {
        return date.toLocaleString('en-US', {year: 'numeric', month: 'long'});
    } else if (type === 'day') {
        return date.toLocaleString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
    } else {
        return date.toISOString().split('-')[0];
    }
}

export const addZeroIfNeeded = (number: number) => {
    if (number < 10) {
        return '0' + number.toString();
    }
    return number;
}

export const Calendar: FC = () => {

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tags, setTags] = useState<ITag[]>([]);
    const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
    const [type, setType] = useState<string>('day');
    const [beginDate, setBeginDate] = useState<Date>(new Date());
    const [items, setItems] = useState<any[]>([]);

    const setCurrentDate = (date: Date) => {
        setType('day');
        setBeginDate(date);
    };

    const isMaxReached = (type: string, selectedTagIds: string[]) => {
        if (type === 'day') {
            return selectedTagIds.filter(item => !!item).length > 4;
        } else if (type === 'week' ) {
            return selectedTagIds.filter(item => !!item).length > 0;
        }
    };

    useEffect(() => {
        const players: string[] = [];
        
        tags.filter(tag => selectedTagIds.includes(tag.uuid))
            .flatMap(tag => tag?.players)
            .forEach((player: IInternalPlayer | undefined) => {
                !!player?.playerNick && !players.includes(player?.playerNick) && players.push(player?.playerNick);
            });

        let startDate = cloneDeep(beginDate);
        let stopDate = cloneDeep(beginDate);
        if (type == 'year') {
            startDate.setMonth(0);
            startDate.setDate(1);
            stopDate.setMonth(11);
            stopDate.setDate(31);
        } else if (type == 'month') {
            startDate.setDate(1);
            stopDate.setMonth(startDate.getMonth() + 1);
            stopDate.setDate(1);
        } else if (type == 'day') {
            stopDate.setDate(startDate.getDate() + 1);
            if (selectedTagIds.length > 5) {
                return;
            }
        } else if (type == 'week') {
            const weekDays = getWeek(startDate);
            startDate = weekDays[0];
            stopDate = weekDays[weekDays.length - 1];
            stopDate.setDate(stopDate.getDate() + 1);
            if (selectedTagIds.length > 1) {
                return;
            }
        }
        const innerTags = tags.filter(innerTag => selectedTagIds.includes(innerTag.uuid));
        setIsLoading(true);
        fetch(`http://146.59.44.6:8080/calendar/nicks${!!groupId ? '?groupId=' + groupId : ''}`, { 
            method: "POST",
            mode: 'cors',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({
                nicks: players,
                startDate: startDate.getFullYear() + "-" + addZeroIfNeeded(startDate.getMonth() + 1) + "-" + addZeroIfNeeded(startDate.getDate()),
                stopDate: stopDate.getFullYear() + "-" + addZeroIfNeeded(stopDate.getMonth() + 1) + "-" + addZeroIfNeeded(stopDate.getDate()),
            })
        }).then(res => res.ok ? res.json() : [])
        .then(json => json.map((element: any) => {
            return {
                date: element[1],
                nick: element[0]?.nick,
                tags: innerTags.filter(tag => (tag.players?.filter(player => player.playerNick === element[0]?.nick).length || 0) > 0)
            }
        }))
        .then(innerItems => {
            setIsLoading(false);
            setItems(innerItems);
        });
    }, [selectedTagIds, beginDate, type]);

    useEffect(() => {
        if (type === 'week') {
            setSelectedTagIds((prevState: string[]) => {
                const final = [];
                final.push(prevState[0]);
                return final;
            })
        } else if (type === 'day') {
            setSelectedTagIds((prevState: string[]) => {
                const final = prevState.slice(0, 5);
                return final;
            })
        }
    }, [type]);

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/tagPlayer/calendar${!!groupId ? '?groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => setTags(json));
    }, []);

    if (!userId) {
        window.location.href = '/login';
    } else if (!isAdmin) {
        window.location.href = '/';
    }

    return (<>
        <Header itemSelected={8} />
        <CalendarSection>
            <ConfigSection isLoading={isLoading}>
                <div>
                    <span>SELECTED</span>
                    {
                        tags.filter(innerTag => selectedTagIds.includes(innerTag.uuid)).map(tag => (<TagClick onClick={() => {
                            isLoading || setSelectedTagIds((prevState: string[]) => prevState.filter(innerTag => innerTag !== tag.uuid));
                        }}>
                            <Tag uuid={tag.uuid} name={tag.name} color={tag.color} bgColor={tag.bgColor} />
                        </TagClick>))
                    }
                </div>
                <div>
                    <span>AVAILABLE</span>
                    {
                        tags.filter(innerTag => !selectedTagIds.includes(innerTag.uuid)).map(tag => (<TagClick isMaxReached={isMaxReached(type, selectedTagIds)} onClick={() => {
                            isLoading || isMaxReached(type, selectedTagIds) || setSelectedTagIds((prevState: string[]) => {
                                !!tag.uuid && (prevState.includes(tag.uuid) || prevState.push(tag.uuid));
                                return [...prevState];
                            });
                        }}>
                            <Tag uuid={tag.uuid} name={tag.name} color={tag.color} bgColor={tag.bgColor} />
                        </TagClick>))
                    }
                </div>
            </ConfigSection>
            <MainSection>
                <CalendarHeader 
                    title={getTitle(beginDate, type)}
                    type={type}
                    setToday={() => setBeginDate(new Date())}
                    setType={setType} 
                    prevDate={() => {
                        if (type === 'day') {
                            setBeginDate((prevState: Date) => {
                                const newDate = cloneDeep(prevState);
                                newDate.setDate(newDate.getDate() - 1);
                                return newDate;
                            });
                        } else if (type === 'week') {
                            setBeginDate((prevState: Date) => {
                                const newDate = cloneDeep(prevState);
                                newDate.setDate(newDate.getDate() - 7);
                                return newDate;
                            });
                        } else if (type === 'month') {
                            setBeginDate((prevState: Date) => {
                                const newDate = cloneDeep(prevState);
                                newDate.setMonth(newDate.getMonth() - 1);
                                return newDate;
                            });
                        } else if (type === 'year') {
                            setBeginDate((prevState: Date) => {
                                const newDate = cloneDeep(prevState);
                                newDate.setFullYear(newDate.getFullYear() - 1);
                                return newDate;
                            });
                        }
                    }} 
                    nextDate={() => {
                        if (type === 'day') {
                            setBeginDate((prevState: Date) => {
                                const newDate = cloneDeep(prevState);
                                newDate.setDate(newDate.getDate() + 1);
                                return newDate;
                            });
                        } else if (type === 'week') {
                            setBeginDate((prevState: Date) => {
                                const newDate = cloneDeep(prevState);
                                newDate.setDate(newDate.getDate() + 7);
                                return newDate;
                            });
                        } else if (type === 'month') {
                            setBeginDate((prevState: Date) => {
                                const newDate = cloneDeep(prevState);
                                newDate.setMonth(newDate.getMonth() + 1);
                                return newDate;
                            });
                        } else if (type === 'year') {
                            setBeginDate((prevState: Date) => {
                                const newDate = cloneDeep(prevState);
                                newDate.setFullYear(newDate.getFullYear() + 1);
                                return newDate;
                            });
                        }
                    }} 
                />
                { isLoading ? (<Loading>LOADING...</Loading>) : (<>
                    { type === 'day' && (<DayView 
                        date={beginDate} 
                        items={items} 
                        tags={tags.filter(tag => selectedTagIds.includes(tag.uuid))} />)}
                    { type === 'week' && (<WeekView date={beginDate} items={items} tags={tags} />)}
                    { type === 'month' && (<MonthView 
                        tags={tags.filter(tag => selectedTagIds.includes(tag.uuid))}
                        date={beginDate} 
                        withExtraDays={true} 
                        baseDate={beginDate} 
                        items={items}
                        setCurrentDate={setCurrentDate} />)}
                    { type === 'year' && (<YearView 
                        tags={tags.filter(tag => selectedTagIds.includes(tag.uuid))}
                        date={beginDate} 
                        items={items} 
                        setCurrentDate={setCurrentDate}
                        setCurrentMonth={(date: Date) => {
                            setType('month');
                            setBeginDate(date);
                        }}/>)}
                </>)
                }
            </MainSection>
        </CalendarSection>
    </>);
};

const CalendarSection = styled.div`
    display: flex;
    margin: 0 auto;
    margin-top: 127px;
    max-width: 1300px;
`;

const ConfigSection = styled.div<{isLoading: boolean}>`
    width: 250px;
    border-right: 2px solid ${colors.border};

    > div {
        padding: 16px;
        background-color: ${colors.white};
        display: flex;
        flex-direction: column;
        gap: 16px;
        ${p => p.isLoading && `
            opacity: 0.25;
            pointer-events: none;
        `}

    }

    > div:first-child {
        border-bottom: 2px solid ${colors.border};
    }
`;

const MainSection = styled.div`
    width: 100%;
`;

const TagClick = styled.div<{isMaxReached?: boolean}>`
    ${p => !p.isMaxReached ? `
        cursor: pointer;
        
        :hover {
            text-decoration: underline;
            opacity: 0.7;
        }` : `
        opacity: 0.25;
        pointer-events: none;
        `
    };
`;