import React, { FC, useEffect, useState } from "react";

import IHand from "../../types/Hand.types";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { PlayersList } from "./PlayersList";
import { ActionSection } from "./ActionSection";

import { cloneDeep } from "lodash";
import { Showdown } from "./Showdown";
import icons, { Icon } from "../commons/Icon";
import ITag from "../../types/Tag.types";
import { Checkbox } from "@chakra-ui/react";
import IPlayerGlobal from "../../types/IPlayerGlobal";


const actions = ['BLIND' , 'PREFLOP', 'FLOP', 'TURN', 'RIVER'];

const expectedBoardSize = [0, 0, 3, 4, 5];

interface IHandDetails extends IHand {
    hideDetails?: () => void;
    fullRadius?: boolean;
    heroNick: string;
    allPlayers: IPlayerGlobal[];
    setReviewed?: (uuid: string) => void;
}

export const HandDetails: FC<IHandDetails> = ({
    uuid,
    title,
    players,
    bb,
    board,
    blind,
    preflop,
    flop,
    turn,
    river,
    pot,
    heroCards,
    date,
    hideDetails,
    fullRadius,
    heroNick,
    allPlayers,
    reviewed,
    setReviewed
}) => {

    const [allTags, setAllTags] = useState<ITag[]>([]);
    const [allTagPlayers, setAllTagPlayers] = useState<ITag[]>([]);
    const [polishTime, setPolishTime] = useState<boolean>(true);
    const [actualReviewed, setActualReviewed] = useState<boolean>(reviewed || false);

    const cards = [
        heroCards,
        cloneDeep(board).splice(0, 3),
        cloneDeep(board).splice(0, 4),
        cloneDeep(board).splice(0, 5)
    ];

    const actionsList = [
        blind,
        preflop,
        flop,
        turn,
        river
    ];

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/tag${!!groupId ? '?groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => setAllTags(json));

        fetch(`http://146.59.44.6:8080/tagPlayer?withPlayers=true${!!groupId ? '&groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => setAllTagPlayers(json));
    }, []);

    let datetime = date.split(' ');
    if (datetime.length > 2) {
        datetime = [datetime[0], datetime[2]];
    }
    
    if (datetime?.[1].length < 8) { 
        datetime[1] = '0' + datetime[1];
    }
    const finalDate = datetime?.[0] + ' ' + datetime?.[1];
    const dateObject = new Date(finalDate+'+09:00'); 

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;

    return (<HandDetailsContainer 
        id={'hand'} 
        className='visible-scrollbar' 
        larger={!hideDetails} 
        onClick={e => e.stopPropagation()} 
        fullRadius={fullRadius}>
        <HeaderRow>
            <div>
                <h2>{ title }</h2>
                <Row>
                    <div>{ players.length } Players, { (dateObject.toLocaleString("en-GB", {timeZone: polishTime ? "Europe/Warsaw" : "Asia/Seoul"})) } { polishTime ? 'PL' : 'KOR'}, Korean</div>
                    <Checkbox 
                        color={colors.white}
                        icon={<></>} 
                        onChange={e => {
                        setPolishTime(!polishTime);
                        e.stopPropagation();
                    }}>{polishTime ? 'KOREAN' : 'POLISH'} TIME</Checkbox>
                </Row>
            </div>
            <Row>
                { !!uuid && (<ReviewBox reviewed={actualReviewed} onClick={() => {
                        if (!actualReviewed) {
                            fetch(`http://146.59.44.6:8080/hand/setReviewed/${uuid}${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                                method: "POST",
                                headers: { 'Content-Type': 'application/json'}
                            }).then(() => {
                                setActualReviewed(true);
                                !!setReviewed && setReviewed(uuid);
                            });
                        }
                    }}>{ actualReviewed ? 'REVIEWED' : 'SET AS REVIEWED'}</ReviewBox>)
                }
                <Icon 
                    icon={icons.TableIcon}
                    onClick={() => {
                        const tableNicks = players.map(player => player.nick);
                        const finalNicks = tableNicks.concat(Array(9 - tableNicks.length));
                        for (let i = 1; i < 5; i++) {
                            const tmp = finalNicks?.[i] || '';
                            finalNicks[i] = finalNicks?.[finalNicks?.length - i] || '';
                            finalNicks[finalNicks?.length - i] = tmp;
                        }
                        window.location.href = `/tables?data=[${JSON.stringify(finalNicks)}]`;
                    }}
                    fill={colors.soft}/>
                {
                    hideDetails && (<Icon 
                        icon={icons.CloseIcon}
                        onClick={hideDetails} 
                        fill={colors.soft}/>)
                }
            </Row>
        </HeaderRow>
        <PlayersList
            allPlayers={allPlayers}
            allTags={allTagPlayers}
            players={players}
            bb={bb}
            heroNick={heroNick}/>
        {
            cards.map((card, idx) => ((actionsList?.[idx + 1]?.length > 0 || card.length === expectedBoardSize[idx + 1]) && (<ActionSection
                title={actions?.[idx + 1]} 
                players={players} 
                actions={actionsList?.[idx + 1]}
                heroNick={heroNick}
                bb={bb}
                pot={pot[idx] || 0}
                cards={card} 
                withBorder={idx !== cards.length}/>)))
        }
        <Showdown 
            board={board}
            players={players} 
            larger={!hideDetails}
            bb={bb}
            heroNick={heroNick}
            allTags={allTags}
            actions={actionsList} />
    </HandDetailsContainer>);
};

const HandDetailsContainer = styled.div<{larger?: boolean, fullRadius?: boolean}>`
    width: 840px;
    overflow: scroll;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: ${colors.tile.background};
    color: ${colors.whiteStrong};
    padding: 16px 0;
    border-radius: ${p => p.fullRadius ? '16px' : '0 0 16px 16px'};
    max-height: calc(100vh - 48px);

    h2 {
        font-size: 21px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 4px 0;
        font-family: sans-serif;
    }

    > * {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

const HeaderRow = styled.div`
    display: flex;
    justify-content: space-between;

    > div > div {
        color: ${colors.soft};
        margin-bottom: 8px;
        font-size: 16px;
    }

    path {
        fill: ${colors.whiteStrong};
    }
`;

const Row = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;

    input {
        align-self: flex-end;
    }
`;

const ReviewBox = styled.div<{reviewed?: boolean}>`
    color: ${p => p.reviewed && colors.success} !important;

    &:hover {
        cursor: ${p => !p.reviewed && 'pointer'};
        opacity: ${p => !p.reviewed && 0.7};
    }
`;