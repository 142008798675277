import React, { 
    FC, 
    useEffect, 
    useState, 
} from 'react';
import { Header } from '../Header/Header';
import IPlayer from '../../types/Player.types';
import IHand from '../../types/Hand.types';
import { uniq } from 'lodash';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { SearchBox } from '../SearchBox/SearchBox';
import { HandListContainer } from './Players';
import { useParams } from "react-router-dom";
import { Tag } from '../commons/Tag';
import ITag from '../../types/Tag.types';
import { TagsContainer } from '../HandDetails/Showdown';

export const PlayersList: FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [players, setPlayers] = useState<string[]>([]);
    const [tagPlayers, setTagPlayers] = useState<string[]>([]);
    const [hands, setHands] = useState<IHand[]>([]);
    const [tag, setTag] = useState<ITag | null>(null);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;

    const params = useParams();
    
    if (!userId) {
        window.location.href = '/login';
    }

    const onSearch = async (nick: string) => {
        if (!isLoading && !!nick && nick.length > 0) {
            setIsLoading(true);
            const res = await fetch(`http://146.59.44.6:8080/hand?nick=${nick}${!!userId ? '&uuid=' + userId : ''}${!!groupId ? '&groupId=' + groupId : ''}`,{ 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            });
            const text = await res.text();
            const json = await JSON.parse(text.trim());
            json?.length > 0 && (window.location.href = `/players/${nick}/hands`);
            setIsLoading(false);
        }

        document.title = `Players - Korean`;
    }

    useEffect(() => {
        if (params?.tagId) {
            fetch(`http://146.59.44.6:8080/tagPlayer/tag/${params?.tagId}${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            }).then(res => res.text())
            .then(text => {
                const jsonObj = JSON.parse(text);
                setTag(jsonObj);
                setTagPlayers(jsonObj.players.map((player: any) => player?.playerNick));
            });
        } else {
            setIsLoading(true);
            fetch(`http://146.59.44.6:8080/hand/newest?limit=10${!!userId ? '&uuid=' + userId : ''}${!!groupId ? '&groupId=' + groupId : ''}`, { 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            }).then(res => res.text())
            .then(text => JSON.parse(text))
            .then(json => {
                setHands(json);
                setPlayers(uniq(json.flatMap((hand: IHand) => hand.players).map((player: IPlayer) => player.nick)));
                setIsLoading(false);
            });
        }     
    }, []);

    return(<>
        <Header itemSelected={1} />
        <HandListContainer marginTop={227}>
            <SearchBox onSearch={onSearch} setHeroNick={() => {}} isLoading={isLoading} />
            { params?.tagId && (<TagsContainer>
                <Tag uuid={params?.tagId || ''} name={tag?.name || ''} color={tag?.color || ''} bgColor={tag?.bgColor || ''} />
            </TagsContainer>) }
            <PlayersContainer manyCols={!!params?.tagId}>
                { !!params?.tagId || (<PlayerRow>
                    <div>NICK</div>
                    <Column>
                        <Row>
                            <div>HAND</div>
                            <div>DATE</div>
                        </Row>
                    </Column>
                </PlayerRow>) }
            {
                !params?.tagId ? players?.map(mainPlayer => (<PlayerRow onClick={() => {
                    window.location.href = `/players/${mainPlayer}/hands`;
                }}>
                    <div>{ mainPlayer }</div>
                    <Column>
                    {
                        hands.filter((hand: IHand) => hand.players.filter((player: IPlayer) => player.nick === mainPlayer)?.length > 0)
                            .map((hand: IHand) => {
                                let datetime = hand.date.split(' ');
                                if (datetime.length > 2) {
                                    datetime = [datetime[0], datetime[2]];
                                }
                                
                                if (datetime?.[1].length < 8) { 
                                    datetime[1] = '0' + datetime[1];
                                }
                                const finalDate = datetime?.[0] + ' ' + datetime?.[1];
                                const dateObject = new Date(finalDate+'+09:00');
                                return (<Row>
                                    <div>{hand.title}</div>
                                    <div>{dateObject.toLocaleString("en-GB", {timeZone: "Europe/Warsaw"})}</div>
                                </Row>)
                            })
                    }
                    </Column>
                </PlayerRow>)) : tagPlayers?.map(mainPlayer => (<PlayerRow onClick={() => {
                    window.location.href = `/players/${mainPlayer}/hands`;
                }}>
                    <div>{ mainPlayer }</div>
                </PlayerRow>))
            }
            </PlayersContainer>
        </HandListContainer>
    </>);
};

const PlayersContainer = styled.div<{manyCols?: boolean}>`
    display: ${p => p.manyCols ? 'grid' : 'flex'};
    grid-template-columns: ${p => p.manyCols && '1fr 1fr 1fr 1fr'};
    > div {
        justify-content: ${p => p.manyCols && 'center'};
    }
    flex-direction: column;
    gap: ${p => p.manyCols ? 20 : 4}px;
    margin: 0 auto;
    padding: 32px;
    box-sizing: border-box;
    max-width: 600px;
    width: fit-content;
    color: ${colors.whiteStrong};
    font-size: 16px;
    flex-grow: 1;
    flex-basis: 0;

    > div${p => p.manyCols ? '' : ':not(:first-child)'} {
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    > div:first-child {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        border-bottom: ${p => !p.manyCols && `3px double ${colors.white}`};
    }
`;

const PlayerRow = styled.div`
    padding: 8px;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.white};

    > div:first-child {
        font-size: 20px;
        font-weight: 600;
        justify-content: space-between;

        > div {
            justify-content: space-between;
        }
    }
`;

const Row = styled.div`
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    gap: 16px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 280px;

    > div > div:first-child {
        min-width: 110px;
    }

    > div > div:nth-child(2) {
        min-width: 150px;
    }
`;