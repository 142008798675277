import React, {
    FC, 
    FunctionComponent, 
    SVGProps, 
    useState,
} from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/icons/IconX.svg';
import { ReactComponent as UpArrowIcon } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/down-arrow.svg';
import { ReactComponent as TickIcon } from '../../assets/icons/tick.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as TableIcon } from '../../assets/icons/table-icon.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus-icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-icon.svg';
import { ReactComponent as NoTickIcon } from '../../assets/icons/no-tick-icon.svg';
import { colors } from '../../styles/colors';

const icons = {
    CloseIcon,
    UpArrowIcon,
    DownArrowIcon,
    TickIcon,
    AddIcon,
    TableIcon,
    LeftArrowIcon,
    RightArrowIcon,
    CopyIcon,
    MinusIcon,
    TrashIcon,
    NoTickIcon,
};

export default icons;

interface IProps {
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    fill?: string;
    stroke?: string;
    scale?: number;
    to?: string;
    height?: number;
    maxWidth?: number;
    onHover?: string;
    isHoverEnabled?: boolean;
    width?: string | number;
    onClick: () => void;
    tooltip?: any;
}

export const Icon: FC<IProps> = ({
    icon: IconComponent,
    fill = 'none',
    stroke = 'current',
    scale = 1,
    to = 'left bottom',
    maxWidth = 500,
    height = 30,
    onHover = '#ffffff',
    isHoverEnabled = false,
    width,
    onClick,
    tooltip
}) => {
    const [isMouseOn, setIsMouseOn] = useState(false);
    return (<>
        <Body
            onClick={onClick}
            onMouseEnter={() => setIsMouseOn(true)}
            onMouseLeave={() => setIsMouseOn(false)}
            maxWidth={maxWidth}
            to={to}
            scale={scale}
            fill={isMouseOn && isHoverEnabled ? onHover : fill}
        >
            <IconComponent height={height} width={width} stroke={stroke} />
        </Body>
        {
            !!tooltip && isMouseOn && (<TooltipData> { tooltip }</TooltipData>)
        }
    </>);
};

const Body = styled.div<{fill: string, scale: number, to: string, maxWidth: number}>`
    transform: scale(${p => p.scale});
    transform-origin: ${p => p.to};
    line-height: 1;
    cursor: pointer;

    path {
        stroke: ${p => p.fill};
    }
`;

const TooltipData = styled.div`
    position: absolute;
    border-radius: 8px;
    border: 2px solid ${colors.whiteStrong};
    margin-left: 320px;
    z-index: 1000000;
    opacity: 1;
    padding: 8px;
    color: ${colors.whiteStrong};
    background-color: ${colors.background.primary};
    display: flex;
    flex-direction: column;
    gap: 4px;
`;